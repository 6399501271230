     import React from 'react';

import Layout from '../components/layout';
import Slider from '../components/slider';
import { Box, Text, Stack, _hover, Flex } from '@chakra-ui/core';
import PageWrapper from '../components/page.wrapper';

import {
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
    FaGithub,
    FaEnvelope,
    FaPhoneAlt,
    FaMapMarkerAlt,
    FaInstagram,
} from 'react-icons/fa';

const img = require('../images/innerbanner.jpg');
const imgNew = require('../images/carehome/nursing-care1.jpg');
const imgNew2 = require('../images/carehome/nursing-care3.jpg');


const AdvanceNursingCare = () => (
    <Layout>
        <Box className="sub-menu">
            <Box className="sub-banner" style={{ backgroundImage: `url(${img})` }} />
            <Box className="content-row">
				<h1>Advance Nursing Care</h1>
                <Flex className="flex-row">
                    <Box flex="1" p="4">
                        <Box as="img" src={imgNew} w="100%" />
						<Box as="img" src={imgNew2} w="100%" mt="3" />
                    </Box>

                    <Box flex="1" p="4" className="brief-para">
                        <p>
                            <b>Routine Bed Side Care :</b> Routine care of bed ridden patience and
                            patients with restricted mobility in their daily activities & personal
                            hygiene.
                            <br />
                            <br />
                            <b>Elderly Care :</b> Assistance in daily activities , preventing falls
                            and injuries providing emotional support and companionship.
                            <br />
                            <br />
                            <b>Advance /Specialized Nursing Care :</b> provide a range of quality
                            staff with Specialized care of patience for with critical conditions.
                            <br />
                            <br />
                            <b>Baby Care :</b> Specialized care for your beloved babies with
                            experienced staff.
                            <br />
                            <br />
                            <b>Post-Operative Care :</b> Complete care post a surgery ensuring
                            eventful healing & reducing the need for a re-hospitalization.
                            <br />
                            <br />
                            <b>Rehabilitation of Medical & physiotherapy</b> to be provided at our
                            authorised centre
                        </p><br/>
						<h2>Short Term Nursing services at Home</h2><br/>
						<p>
							<b>Wound Care :</b> dressing including diabetics wound care & Wound management . <br />
                            <br />
								<b>Infusion :</b> Intravenous fluid and electrolyte administration for various conditions. <br />
                            <br /> 
								<b>Catheterization :</b> complete catheter care monitoring and management Insertion. <br />
                            <br />
								<b>Injection :</b> IV ( Intra-Venous) or IM( Intra-muscular) administration of prescribed  									   medicines. <br />
                            <br /> 
								<b>Stoma Care :</b> Complete care management of patience with stoma. <br />
                            <br /> 
								<b>Suture removal :</b> Professional nurse removal of suture without pain as prescribed by the                                    Dr <br />
                            <br />
								<b>Vital Checks:</b> Monitoring of vital parameters like blood pressure, oxygen saturation,    	                                  pulse etc. <br />
                            <br /> 
                         </p>
                    </Box>
                </Flex>
            </Box>
        </Box>
    </Layout>
);

export default AdvanceNursingCare;